.animated {
    animation: up-down 3s ease-in-out infinite alternate-reverse both;
}

.animate__animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-duration: var(--animate-duration);
    animation-duration: var(--animate-duration);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.animate__animated.animate__slow {
    -webkit-animation-duration: calc(1s * 2);
    animation-duration: calc(1s * 2);
    -webkit-animation-duration: calc(var(--animate-duration) * 2);
    animation-duration: calc(var(--animate-duration) * 2);
  }

@keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
}
.animate__fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}