#header {
    background: rgba(0, 0, 0, 0.75);
    transition: all 0.5s;
    z-index: 997;
    padding: 15px 0;
}

#header .logo {
    font-size: 28px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    /* border: 2px solid lawngreen; */
    width: 100%;
}

#header .logo a {
    color: #fff;
}

#header .logo img {
    max-height: 40px;
}

/* Desktop Navigation */

header.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    display: inline-flex;
    transition: all 0.2s ease-in-out;
    background-color: transparent;
    opacity: 1;
}

header .header_actions > a {
    font-size: 15px;
    font-family: "Rubik", sans-serif;
    color: #fff;
    font-weight: normal;
    max-width: 280px;
    display: inline-flex;
    height: 100%;
    position: relative;
    padding: 0 25px;
    background-color: transparent;
    text-decoration: none;
    margin: 0 10px;
}

header .header_actions > a:hover {
    color: #38abff;
    transition: all 0.2s ease-in-out;
}

@media screen and (max-width: 480px) and (orientation: portrait) {
    .navbar-logo {
        transform: scale(1);
    }
}
@media screen and (max-height: 480px) and (orientation: landscape) {
    .navbar-logo {
        position: absolute;
        left: 35vw;
        transform: scale(1);
    }
}
/* @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .navbar-logo {
        position: absolute;
        left: 35vw;
        transform: scale(1);
    }
} */

#header {
    height: 80px;
    transition: all 0.5s;
    z-index: 997;
    transition: all 0.5s;
    padding: 15px 0;
    background: #1e4356;
}

#header.header-transparent {
    background: none;
}

#header.header-scrolled {
    background: rgba(30, 67, 86, 0.8);
    height: 60px;
    padding: 10px 0;
}

#header .logo h1 {
    font-size: 28px;
    margin: 0;
    padding: 4px 0;
    line-height: 1;
    font-weight: 400;
    letter-spacing: 3px;
    text-transform: uppercase;
}

#header .logo h1 a,
#header .logo h1 a:hover {
    color: #fff;
    text-decoration: none;
}

#header .logo img {
    padding: 0;
    margin: 0;
    max-height: 40px;
}

.back-to-top {
    position: fixed;
    /* display: none; */
    width: 40px;
    height: 40px;
    border-radius: 1rem;
    top: 1.25rem;
    left: 1.25rem;
    background: transparent;
    border: 2px solid #fff;
    transition: display 0.5s ease-in-out;
    z-index: 10;
}

.back-to-top #back-to-top-icon {
    transform: scale(0.4);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
}

.back-to-top:hover {
    color: #fff;
    background: #c3c3c3;
    transition: background 0.2s ease-in-out;
}
