.btn-main {
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 8px 20px;
    border-radius: 50px;
    transition: 200ms;
    margin: 10px;
    border: 3px solid #000e22;
    color: #fff !important;
    background: #000e22;
    text-decoration: none;
}

.btn-navbar {
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 8px 20px;
    border-radius: 50px;
    transition: 200ms;
    margin: 10px;
    border: 3px solid #000e22;
    color: #fff !important;
    background: #505b97;
    text-decoration: none;
}

.btn-main-muted {
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 8px 20px;
    border-radius: 50px;
    transition: 200ms;
    margin: 10px;
    border: 3px solid #898989;
    color: #fff !important;
    background: #898989;
}

.btn-main:hover,
.btn-main-muted:hover {
    background: rgba(0, 14, 34, 0.7);
    cursor: pointer;
    text-decoration: none;
}

#linkedin-icon {
    max-width: 150px;
}
