h1 {
  font-size: 30px;
}

.headerLink {
  cursor: pointer;
}

.headerLink span {
  color: #595959;
}

.headerLink:hover span {
  color: rgb(228, 60, 92);
}
