#eyrid-hero {
    width: 100%;
    height: 100vh;
    margin-top: 0;
    padding: 0;
    background: rgb(255 255 255);
    overflow-y: hidden;
}

#eyrid-hero h1 {
    margin: 0 0 10px 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    color: black;
}

#eyrid-hero h2 {
    color: #484848;
    margin-bottom: 50px;
    font-size: 24px;
}

#eyrid-hero .animated {
    animation: up-down 3s ease-in-out infinite alternate-reverse both;
}

#eyrid-hero .eyrid-hero-img {
    max-width: 600px;

    transform: translateY(20%);
}

@media (min-width: 1024px) {
    #eyrid-hero {
        background-attachment: fixed;
    }
}

@media (max-width: 991px) {
    #eyrid-hero {
        height: 100vh;
    }
    #eyrid-hero .animated {
        -webkit-animation: none;
        animation: none;
    }
    #eyrid-hero .eyrid-hero-img {
        text-align: center;
    }
    #eyrid-hero .eyrid-hero-img img {
        width: 50%;
    }
}

@media (max-width: 768px) {
    #eyrid-hero {
        margin-top: 0px;
    }
    #eyrid-hero h1 {
        font-size: 28px;
        line-height: 36px;
    }
    #eyrid-hero h2 {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 30px;
    }
    #eyrid-hero .eyrid-hero-img img {
        width: 70%;
    }
}

@media (max-width: 575px) {
    #eyrid-hero .eyrid-hero-img img {
        width: 80%;
    }
}

@-webkit-keyframes up-down {
    0% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(-10px);
    }
}

@keyframes up-down {
    0% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(-10px);
    }
}
