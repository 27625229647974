.stories-carousel {
    padding: 80px 0;
    background: transparent;
    position: relative;
}

.stories-carousel .card {
    height: 425px;
    border: none;
    background: transparent;
}

.stories-carousel .card-body {
    background: transparent;
}

.stories-carousel .section-header {
    margin-bottom: 40px;
}
