.contact .btn-main {
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 8px 28px;
    border-radius: 50px;
    transition: 0.5s;
    margin: 10px;
    border: 2px solid #fff;
    color: #fff;
    background: #7caaf1;
}

.contact .btn-main:hover {
    background: #000e22;
    border: 2px solid #000e22;
    cursor: pointer;
}

.home .contact {
    background: rgba(50, 53, 66, 0.5);
}

.contact .container {
    border: 1px solid #d5dbe318;
    background: #fff;
    padding: 3em 4em;
    border-radius: 1em;
    box-shadow: 2px 20px 15px rgba(0, 0, 0, 0.1);
}

.contact .contact-image {
    position: relative;
}

.contact .contact-image img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

@media (max-width: 768px) {
    .contact .container {
        padding: 3em 2em;
        border-radius: 1em;
    }
}
