@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);

/* @import url("./eyrid.css"); */
@import url("./animation.css");
@import url("./button.css");
@import url("./header.css");
@import url("./hero.css");
/* @import url("./footer.css"); */
@import url("./story-carousel.css");
@import url("./contact.css");
@import url("./demo-request.css");
@import url("./privacy-policy.css");
