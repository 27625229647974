.cross_position {
  top: 27px;
  right: 10%;
}

.cross_position:hover img {
  cursor: pointer !important;
}

.sticky-top-navbar {
  background-color: #162055;
}

.hero_bg {
  background-image: url("../images/Hero_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.help_btn {
  position: relative;
  padding: 11px 25px;
  background: #162055;
  border-radius: 4px;
  transition: all 300ms linear;
}

.content_1_parent {
  scroll-behavior: smooth;
}

.help_btn::before {
  position: absolute;
  content: "";
  background: linear-gradient(92.67deg, #505b97 5.11%, #01b0f9 114.92%);
  border-radius: 4.25px;
  width: 102%;
  height: 104%;
  left: -1%;
  top: -2%;
  z-index: -1;
  transition: all 300ms linear;
}

.common_btn {
  color: white;
  background: linear-gradient(92.67deg, #01b0f9 5.11%, #505b97 114.92%);
  border: 0.5px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}

.common_btn:hover {
  background: linear-gradient(92.67deg, #505b97 5.11%, #01b0f9 114.92%);
}

.goto_btn {
  padding: 9px 1px;
}

.help_btn:hover {
  box-shadow: 200px 0 0 inset white;
  color: #01b0f9 !important;
}

.read_more_btn {
  padding: 11px 25px;
  background: linear-gradient(92.67deg, #01b0f9 5.11%, #505b97 114.92%);
  border-radius: 5px;
  color: white;
  transition: all 0.3s ease-in-out;
  border: 0;
}

.read_more_btn:hover {
  box-shadow: 300px 0 0 0 #fff inset;
  color: #01b0f9 !important;
}

.btn_login {
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 4.59075px;
  padding: 11px 25px;
  background: linear-gradient(transparent, transparent),
    linear-gradient(92.67deg, #01b0f9 5.11%, #505b97 114.92%);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
}

.btn_login:hover {
  background: linear-gradient(transparent, transparent),
    linear-gradient(92.67deg, #505b97 5.11%, #01b0f9 114.92%);

  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
}

/*============================ NAVBAR ============================ */

/* NAVBAR-CSS */
.navbar-wrapper .navbar-btns button:hover {
  background-color: transparent;
  color: #0ed145;
}

.navbar-wrapper .navbar-btns button:hover .cmmg-soon {
  display: inline-block;
}

.navbar-wrapper .navbar-btns button:hover .launch-app {
  display: none;
}

.navbar-wrapper .hamburger-menu {
  position: relative;
  width: 29px;
  cursor: pointer;
  height: 25px;
  z-index: 23;
}

.navbar-wrapper .first {
  top: 0;
  -webkit-transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
    -webkit-transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
    -webkit-transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms cubic-bezier(0.23, 1, 0.32, 1),
    -webkit-transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.navbar-wrapper .sec {
  top: 11px;
  background: var();
  -webkit-transition: all 0ms 300ms;
  transition: all 0ms 300ms;
}

.navbar-wrapper .third {
  top: 22px;
  -webkit-transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
    -webkit-transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
    -webkit-transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms cubic-bezier(0.23, 1, 0.32, 1),
    -webkit-transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.navbar-wrapper .hamburger-menu span {
  width: 100%;
  height: 3px;
  background-color: #fff;
  border-radius: 10px;
  position: absolute;
  display: inline-block;
  left: 0;
  z-index: 1;
}

.navbar-wrapper .animate .sec {
  opacity: 0;
}

.navbar-wrapper .animate .first {
  top: 11px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1),
    -webkit-transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1),
    -webkit-transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
    -webkit-transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.navbar-wrapper .animate .third {
  top: 11px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1),
    -webkit-transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1),
    -webkit-transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
    -webkit-transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.navbar-wrapper .hamburger-menu.animate span {
  background-color: #fff;
}

.overlay-btns button:hover {
  background-color: transparent;
  color: var();
}

.overlay-links {
  color: hsl(0, 0%, 100%);
  opacity: 1;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: relative;
}

.overlay-links::after {
  position: absolute;
  content: "";
  width: 0%;
  height: 3px;
  left: 0;
  bottom: -5px;
  background-color: #fff;
  opacity: 0.6;
  transition: all 0.3s ease-in-out;
  transform: scale(0);
}

.overlay-links:hover::after {
  width: 100%;
  transition: all 0.3s ease-in-out;
  transform: scale(1);
}

@-webkit-keyframes mymove {
  from {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 1;
  }
}

@keyframes mymove {
  from {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 1;
  }
}

.left,
.right {
  opacity: 0;
}

.fix-screen-mobile {
  height: 100vh;
  overflow: hidden;
}

.left li,
.right li {
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  opacity: 0;
}

@media (min-width: 992px) {
  #overlay {
    display: none;
  }

  .boost_box2 {
    background-image: url("../images/png/boost_frame.png");
    background-size: 100% 100%;
    padding: 100px 47px 50px;
    height: 297px !important;
    transform: translateY(50px);
  }

  .boost .slick-list {
    height: 370px;
  }

  .cyber_img_w_50 {
    right: -6%;
  }

  .dashboard_img_border {
    border: 6px solid #14f2f6;
    border-right: 0;
    border-radius: 19.6899px;
  }
}

@keyframes new {
  0% {
    transform: translateY(-10px);
  }

  25% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-10px);
  }

  75% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(-10px);
  }
}

/* NAVBAR-CSS-END */

.easy_box {
  background-image: url("../images/svg/easy_frame.svg");
  background-size: 100% 100%;
  padding: 35px 28px;
  height: 238px !important;
  transition: all 300ms linear;
  width: 100%;
}

.easy_box:hover {
  background-image: url("../images/svg/easy_frame2.svg");
  background-size: 100% 100%;
  height: 238px !important;
  transition: all 300ms linear;
  width: 100%;
}

.index_minus {
  z-index: -1;
}

.index_minus_2 {
  z-index: -2;
}

/* boost-section */
.boost_box {
  background-image: url("../images/png/boost_frame.png");
  background-size: 100% 100%;
  padding: 100px 47px 50px;
  height: 297px !important;
  transition: all 300ms linear;
}

.boost_box:hover {
  background-image: url("../images/png/boost_frame2.png");
  background-size: 100% 100%;
}

.boost_box2 {
  background-image: url("../images/png/boost_frame.png");
  background-size: 100% 100%;
  padding: 100px 47px 50px;
  height: 297px !important;
  transition: all 300ms linear;
}

.boost_box2:hover {
  background-image: url("../images/png/boost_frame2.png");
  background-size: 100% 100%;
}

.slick-dots {
  position: absolute;
  bottom: -55px !important;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li button:before {
  font-family: slick;
  font-size: 12px !important;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 1 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white !important;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  font-size: 14px !important;
  background: #505b97;
  background-color: #505b97;
  background-image: radial-gradient(
    circle farthest-corner at center,
    rgba(1, 176, 249, 1) 50%,
    rgba(80, 91, 151, 1) 100%
  );
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  background-clip: text;
  -moz-text-fill-color: transparent;
}

.slick-next:before,
.slick-prev:before {
  font-family: slick;
  font-size: 41px !important;
  line-height: 1;
  opacity: 0.75;
  background: #505b97;
  background-color: #505b97;
  background-image: radial-gradient(
    circle farthest-corner at center,
    rgba(1, 176, 249, 1) 50%,
    rgba(80, 91, 151, 1) 100%
  );
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 41px !important;
  height: 41px !important;
}

.hero_fade .slick-prev:before,
.hero_fade .slick-next:before {
  content: "" !important;
  width: 41px !important;
  height: 41px !important;
}

.hero_fade .slick-prev {
  left: -105px !important;
  width: 41px !important;
  height: 41px !important;
}

.hero_fade .slick-next {
  transform: rotate(180deg);
  right: -105px;
  width: 41px !important;
  height: 41px !important;
}

.hero_fade .slick-next:focus,
.hero_fade .slick-next:hover,
.hero_fade .slick-prev:focus,
.hero_fade .slick-prev:hover {
  /* background: url('../images/svg/hero_arw.svg') !important; */
  /* background: unset !important; */
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center;
}

.hero_fade .slick-prev,
.hero_fade .slick-next {
  background-image: url("../images/svg/hero_arw.svg") !important;
  background-size: contain;
  width: 41px !important;
  height: 41px !important;

  background-repeat: no-repeat;
  background-position: center;
}

.slick-prev {
  left: -43px !important;
  width: 41px !important;
  height: 41px !important;
}

.slick-next {
  width: 41px !important;
  height: 41px !important;
}

/* cyber-security */
.right_circle_cyber {
  top: -90%;
}

.side_lines {
  top: 20%;
}

/* plans */
.plans_frame_box {
  background-image: url("../images/png/plans_frame.png");
  background-size: 100% 100%;
  padding: 34px 22px 26px 20px;
  height: 420px !important;
  transition: all 0.2s ease-in-out;
}

/* stories */
.stories_box {
  border: 1px solid;
  border-image-source: linear-gradient(
    92.67deg,
    #01b0f9 5.11%,
    #505b97 114.92%
  );
  border-image-slice: 1;
  padding: 19px 26px;
  height: 100%;
}

.stories_box_hover {
  border: 1px solid transparent;
  /* border-image-source: linear-gradient(92.67deg, #01B0F9 5.11%, #505B97 114.92%); */
  padding: 19px 23px;
  height: 100%;
}

.stories_box_hover:hover {
  border: 1px solid;
  border-image-source: linear-gradient(
    92.67deg,
    #01b0f9 5.11%,
    #505b97 114.92%
  );
  border-image-slice: 1;
}

table tr td {
  opacity: 0.9 !important;
  font-size: var(--fs-sm);
  font-weight: 400;
}

.stories_box table tr td {
  padding-left: 14px;
  vertical-align: top;
}

.stories_box table tr {
  vertical-align: top;
}

.stories_box_hover table tr td {
  padding-left: 14px;
  vertical-align: top;
}

.stories_box_hover table tr {
  vertical-align: top;
}

.story_shadow_bottom {
  bottom: -70%;
}

/* collaborate */

.collaborateshadow_top_minus {
  top: -95%;
}

/* accordian-css-start  */
.accordion-item:first-of-type .accordion-button {
  /* background: linear-gradient(92.67deg, #01B0F9 5.11%, #505B97 114.92%); */
  border-radius: 28px !important;
}

.accordion-item:first-of-type .accordion-button:focus-visible {
  border: none !important;
}

/* .accordion-collapse .collapse .show {} */

.accordion-item:first-of-type .accordion-button {
  border-radius: 38px !important;
}

.accordion-button {
  background: transparent;
  padding: 20px 40px;
  border-radius: 35px !important;
}

.accordion-button:not(.collapsed) {
  background: linear-gradient(92.67deg, #01b0f9 5.11%, #505b97 114.92%);
}

.accordion-button:not(.collapsed)::after {
  background-image: url("../images/svg/arrow_botom.svg");
}

.accordion-button::after {
  background-image: url("../images/svg/arrow_botom.svg");
  background-position: center;
}

.accordion-item {
  background: transparent;
  overflow: hidden;
  border: 2px solid #01b0f9 !important;
  backdrop-filter: blur(146.5px);
  border-radius: 40px !important;
}

.accordion-body {
  padding: 27px 82px 27px 50px;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  box-shadow: none !important;
}

.m_w307 {
  max-width: 307px;
}

/* footer-hover-css--------------------------------------  */
.footer_hov {
  color: #ffffff;
  position: relative;
  text-decoration: none;
  display: inline;
}

.footer_hov::before,
.footer_hov::after {
  content: "";
  position: absolute;
  top: 100%;
  height: 1px;
  width: 50%;
  transform: scaleX(0);
  transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  background-color: currentcolor;
}

.footer_hov::before {
  left: 0;
  transform-origin: left;
}

.footer_hov::after {
  right: 0;
  transform-origin: right;
}

.footer_hov:active::before,
.footer_hov:hover::before,
.footer_hov:active::after,
.footer_hov:hover::after {
  transform: scaleX(1);
}

.gain-bg-img {
  background-image: url("../images/png/gain-bg-img.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.z_index_5 {
  z-index: 5;
}

.z_index_6 {
  z-index: 6;
}

.grid_img path {
  transition: all 0.3s ease-in-out;
}

.grid_img path:hover {
  /* transform: translateY(-8px); */
  opacity: 0.6;
}

/* ====================== PRELOADER CSS START ====================== */
#loading {
  z-index: 999;
  position: fixed;
  background-color: var(--color-black);
  width: 100%;
}

.proloader_img {
  -webkit-animation: loader 1.5s infinite alternate;
  animation: loader 1.5s infinite alternate;
  width: 270px;
}

@-webkit-keyframes loader {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}

@keyframes loader {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}

/* BACK-TO-TOP-CSS-START ====================*/
#scroll svg {
  height: 25px;
  width: 22px;
}

#scrolltop svg {
  height: 25px;
  width: 22px;
}

#scroll {
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 50;
  cursor: pointer;
  font-size: var(--font_lg);
  color: var(--white_color);
  padding: 10px 12px;
  border-radius: 50% !important;
  border: 1px solid var(--color-white);
  background: linear-gradient(94.32deg, #0496ff -29.63%, #28b2ab 82.8%), #d9d9d9;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#scrolltop {
  position: fixed;
  bottom: 62px;
  right: 32%;
  z-index: 50;
  cursor: pointer;
  font-size: var(--font_lg);
  color: var(--white_color);
  padding: 10px 12px;
  border-radius: 50% !important;
  border: 1px solid var(--color-white);
  background: linear-gradient(94.32deg, #0496ff -29.63%, #28b2ab 82.8%), #d9d9d9;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

@keyframes moves {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-5px);
  }

  100% {
    transform: translateY(0);
  }
}

#scroll,
#scrolltop:hover {
  background: linear-gradient(94.74deg, #0496ff -44.65%, #28b2ab 96.92%),
    #ffffff;
  color: var(--color-black) !important;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.373);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.305);
}

.dashboard_img_border {
  max-width: 980px;
}

.side_lines {
  top: 52%;
}

.rotate_y {
  transform: rotateY(180deg);
}

@media (max-width: 1024px) {
  #scrolltop {
    bottom: 11px;
    right: 41%;
  }
}

@media (min-width: 1599.99px) {
  .next_caro {
    right: 6% !important;
  }

  .previous_caro {
    left: 6% !important;
  }
}

@media (max-width: 1599.98px) {
  .custom-vh-100 {
    min-height: 760px;
  }

  .dashboard_img_border {
    max-width: 776px;
  }

  .side_lines {
    top: 44%;
  }
}

@media (max-width: 1400px) {
  .side_lines {
    top: 36%;
  }

  .custom-vh-100 {
    min-height: 720px !important;
  }

  .hero_fade .slick-prev {
    left: -35px !important;
    width: 41px !important;
    height: 41px !important;
  }

  .hero_fade .slick-next {
    right: -35px;
    width: 41px !important;
    height: 41px !important;
  }

  .next_caro {
    right: 1% !important;
  }

  .previous_caro {
    left: 1% !important;
  }
}

@media (max-width: 1199.98px) {
  .custom-vh-100 {
    min-height: 550px !important;
  }

  .side_lines {
    top: 14% !important;
  }
}

@media (min-width: 1400px) {
  .plans_frame_box {
    height: 430px !important;
  }

  .hero_fade .slick-next {
    right: -105px;
    width: 41px !important;
    height: 41px !important;
  }

  .hero_fade .slick-prev {
    left: -105px !important;
    width: 41px !important;
    height: 41px !important;
  }
}

@media (min-width: 1200px) and (max-width: 1599.99px) {
  .custom_container {
    max-width: 1140px !important;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .cyber_img_w_50 {
    width: 50%;
  }
}

@media (min-width: 1200px) {
  .min_vh_75 {
    min-height: 75vh;
  }

  .margin_l_60_cyber_img {
    margin-left: 60px;
  }
}

@media (max-width: 1200px) {
  .side_lines {
    top: 27%;
  }

  .hero_fade .slick-prev,
  .hero_fade .slick-next {
    height: 41px;
    width: 41px;
  }

  .hero_fade .slick-prev {
    left: -20px !important;
    width: 41px !important;
    height: 41px !important;
  }

  .hero_fade .slick-next {
    right: -20px;
    width: 41px !important;
    height: 41px !important;
  }

  .secnd_sec_shadow {
    top: 15% !important;
  }

  .boost_sec_shadow {
    top: 15% !important;
  }

  .next_caro {
    right: 0% !important;
  }

  .previous_caro {
    left: 0% !important;
  }
}

@media (max-width: 992px) {
  .hero_fade .slick-prev,
  .hero_fade .slick-next {
    display: none !important;
    width: 41px !important;
    height: 41px !important;
  }

  .overlay-links {
    color: hsl(0, 0%, 100%);
    opacity: 1;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    position: relative;
  }

  .overlay-links::after {
    position: absolute;
    content: "";
    width: 0%;
    height: 3px;
    left: 0;
    bottom: -5px;
    background-color: #fff;
    opacity: 0.6;
    transition: all 0.3s ease-in-out;
    transform: scale(0);
  }

  .overlay-links:hover::after {
    width: 100%;
    transition: all 0.3s ease-in-out;
    transform: scale(1);
  }

  .mobile-view #overlay {
    -webkit-transition: 0.7s ease;
    transition: 0.7s ease;
    opacity: 1;
    pointer-events: all;
    z-index: 20;
  }

  .mobile-view #overlay .left {
    opacity: 1;
    width: 100%;
    height: 100vh;
  }

  .mobile-view #overlay .left .copyright a {
    font-size: 18px;
    -webkit-animation: mymove 0.9s;
    animation: mymove 0.9s;
    -webkit-animation-delay: 1.2s;
    animation-delay: 1.2s;
    opacity: 0;
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .mobile-view #overlay .left .copyright a:hover {
    text-decoration: none;
  }

  .mobile-view #overlay .left ul li:nth-child(1) {
    -webkit-animation: mymove 0.9s;
    animation: mymove 0.9s;
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .mobile-view #overlay .left ul li:nth-child(2) {
    -webkit-animation: mymove 0.9s;
    animation: mymove 0.9s;
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .mobile-view #overlay .left ul li:nth-child(3) {
    -webkit-animation: mymove 0.9s;
    animation: mymove 0.9s;
    -webkit-animation-delay: 0.7s;
    animation-delay: 0.7s;
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .mobile-view #overlay .left ul li:nth-child(4) {
    -webkit-animation: mymove 0.9s;
    animation: mymove 0.9s;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .mobile-view #overlay .left ul li:nth-child(5) {
    -webkit-animation: mymove 0.9s;
    animation: mymove 0.9s;
    -webkit-animation-delay: 1.3s;
    animation-delay: 1.3s;
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .mobile-view #overlay .left ul li:nth-child(6) {
    -webkit-animation: mymove 0.9s;
    animation: mymove 0.9s;
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s;
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .mobile-view #overlay .left ul li:nth-child(7) {
    -webkit-animation: mymove 0.9s;
    animation: mymove 0.9s;
    -webkit-animation-delay: 1.7s;
    animation-delay: 1.7s;
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  #overlay {
    -webkit-transition: 1s ease !important;
    transition: 1s ease !important;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #162055;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  .stories_box {
    height: 365px !important;
  }

  .secnd_sec_shadow {
    top: 50% !important;
  }

  .fotr_shadow_1 {
    width: 50%;
  }

  .bg_shadow_sec_5 {
    top: 38% !important;
  }

  .hero_shadow {
    width: 100% !important;
  }

  .easy_box {
    padding: 35px 41px;
  }
}

@media (max-width: 767.97px) {
  .right_circle_cyber {
    top: -36% !important;
  }

  .accordion-button {
    padding: 15px 29px;
  }

  .collaborateshadow_top_minus {
    top: -34% !important;
  }

  .logo_w {
    width: 70%;
  }

  .easy_box:hover {
    padding: 35px 26px;
  }

  .easy_box {
    padding: 35px 26px;
  }
}

@media (min-width: 768px) {
  .plans_frame_box:hover {
    transform: scale(1.03) !important;
  }

  .max_width_400 {
    max-width: 400px;
  }
}

@media (max-width: 576px) {
  .w_75 {
    width: 75% !important;
  }

  .side_lines {
    top: 70% !important;
    width: 21% !important;
  }

  .right_circle_cyber {
    top: 20% !important;
    width: 100%;
  }

  .accordion-body {
    padding: 21px 15px 27px 15px;
  }

  .help_btn {
    padding: 11px 16px;
  }

  .secnd_sec_shadow {
    top: 32% !important;
  }

  .boost_sec_shadow {
    top: 32% !important;
  }

  .bg_shadow_sec_5 {
    top: 70% !important;
    width: 100% !important;
    right: 0% !important;
  }

  .fotr_shadow_1 {
    top: -25%;
  }

  .index_minus {
    width: 200px;
  }
}

@media (max-width: 575.98px) {
  .proloader_img {
    max-width: 170px;
  }

  .help_btn::before {
    width: 102%;
    left: -1px;
    top: -1px;
  }
}

.custom-vh-100 {
  min-height: 900px;
}

.secnd_sec_shadow {
  top: -32%;
}

.boost_sec_shadow {
  top: -32%;
}

.top_60 {
  top: 60%;
}

.next_caro {
  right: 7%;
}

.previous_caro {
  left: 7%;
}

.bg_shadow_sec_5 {
  top: 0%;
}

.svg_hover {
  opacity: 0.8;
  transition: all 200ms ease-in-out;
}

.svg_hover:hover {
  opacity: 1;
}

.hero_blur {
  position: relative;
  z-index: 1;
}

.hero_blur::after {
  content: "";
  width: 900px;
  height: 348px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: linear-gradient(92.67deg, #01b0f9 5.11%, #505b97 114.92%);
  filter: blur(250px);
  transform: matrix(-1, 0, 0, 1, 0, 0);
  z-index: -1 !important;
}

.footer_blur {
  position: relative;
  z-index: 1;
}

.footer_blur::before {
  content: "";
  width: 304px;
  height: 180px;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateY(-50%);
  background: linear-gradient(92.67deg, #01b0f9 5.11%, #505b97 114.92%);
  filter: blur(250px);
  transform: matrix(-1, 0, 0, 1, 0, 0);
  z-index: -1 !important;
}

.footer_blur2 {
  position: relative;
  z-index: 1;
}

.footer_blur2::after {
  content: "";
  width: 304px;
  height: 180px;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(-50%);
  background: linear-gradient(92.67deg, #01b0f9 5.11%, #505b97 114.92%);
  filter: blur(250px);
  transform: matrix(-1, 0, 0, 1, 0, 0);
  z-index: -1 !important;
}

.faq_blur {
  position: relative;
  z-index: 1;
}

.faq_blur::after {
  content: "";
  width: 381px;
  height: 300px;
  position: absolute;
  bottom: 60%;
  left: -14%;
  transform: translateY(-50%);
  background: linear-gradient(92.67deg, #01b0f9 5.11%, #505b97 114.92%);
  filter: blur(250px);
  transform: matrix(-1, 0, 0, 1, 0, 0);
  z-index: -1 !important;
}

.fotr_shadow_1 {
  top: -80%;
}

.pot_position {
  bottom: -8%;
  left: -6%;
  z-index: -1;
  animation: pot 1.5s infinite alternate;
}

@keyframes pot {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

@media (max-width: 374.98px) {
  body,
  html {
    overflow-x: hidden !important;
  }
}
