@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@400;500;600;700&family=Poppins:wght@300;400;500;600;700&display=swap');

* {
    margin: 0;
    padding: 0;
    list-style-type: none !important;
    text-decoration: none !important;
}

.text-scroll-container {
    display: flex;
    justify-content: center;
}

.text-scroll {
    display: flex;
    justify-content: center;
    align-items: center;
    left: 28%;
    animation-name: textSCroll;
    animation-duration: 20s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    transform: translateX(200px);
}

.custom_img_slider {
    max-width: 248px;
    padding: 24px;
}

@keyframes textSCroll {
    0% {
        transform: translateX(100%);
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);
        -o-transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}

.ff_bai {
    font-family: 'Bai Jamjuree',
        sans-serif;
}

.ff_poppin {
    font-family: 'Poppins',
        sans-serif;
}

:root {
    /* COLOR */
    --light-red: #f25f60;
    --light-pink: #9b00ff;
    --light-green: #2f7500;
    --light-green2: #70bf6d;
    --light-blue: #0083e0;
    --light-blue2: #35a8fe;
    --light-blue3: #38a9fc;
    --orange: #ffab15;
    --pink: #cc00ff;
    --green: #67ff00;
    --blue: #00b2ff;
    --grey: #8B95B0;
    --dark-blue: #162055;

    /* FONT SIZES */
    --fs-xxs: 12px;
    --fs-xs: 14px;
    --fs-sm: 16px;
    --fs-md: 18px;
    --fs-lg: 20px;
    --fs-xl: 24px;
    --fs-2xl: 26px;
    --fs-3xl: 28px;
    --fs-4xl: 30px;
    --fs-5xl: 40px;
    --fs-6xl: 48px;
    --fs-7xl: 52px;
}

@media (max-width: 1399.98px) {
    :root {
        --fs-sm: 14px;
    }
}

@media (max-width: 1199.98px) {
    :root {
        --fs-sm: 13px;
        --fs-xl: 20px;
        --fs-2xl: 27px;
        --fs-3xl: 33px;
        --fs-4xl: 37px;
        --fs-5xl: 42px;
        --fs-6xl: 42px;

    }
}

@media (max-width: 991.98px) {
    :root {
        --fs-xs: 12px;
        --fs-md: 16px;
        --fs-2xl: 24px;
        --fs-3xl: 30px;
        --fs-5xl: 34px;
        --fs-6xl: 40px;
    }
}

@media (max-width: 767.98px) {
    :root {
        --fs-xl: 19px;
        --fs-2xl: 23px;
        --fs-3xl: 21px;
        --fs-5xl: 34px;
        --fs-6xl: 38px;
    }
}

@media (max-width: 575.98px) {
    :root {
        --fs-sm: 13px;
        --fs-lg: 16px;
        --fs-xl: 16px;
        --fs-2xl: 15px;
        --fs-3xl: 21px;
        --fs-5xl: 32px;
        --fs-6xl: 31px;
    }
}

.fs_xxs {
    font-size: var(--fs-xxs) !important;
}

.fs_xs {
    font-size: var(--fs-xs) !important;
}

.fs_sm {
    font-size: var(--fs-sm) !important;
}

.fs_md {
    font-size: var(--fs-md) !important;
}

.fs_lg {
    font-size: var(--fs-lg) !important;
}

.fs_xl {
    font-size: var(--fs-xl) !important;
}

.fs_2xl {
    font-size: var(--fs-2xl) !important;
}

.fs_3xl {
    font-size: var(--fs-3xl) !important;
}

.fs_4xl {
    font-size: var(--fs-4xl) !important;
}

.fs_5xl {
    font-size: var(--fs-5xl) !important;
}

.fs_6xl {
    font-size: var(--fs-6xl) !important;
}

.fs_7xl {
    font-size: var(--fs-7xl) !important;
}

/* COLORS CLASSES */
.color_red {
    color: var(--light-red) !important;
}

.light_pink {
    color: var(--light-pink) !important;
}

.light_green {
    color: var(--light-green) !important;
}

.light_green2 {
    color: var(--light-green2) !important;
}

.light_blue {
    color: var(--light-blue) !important;
}

.light_blue2 {
    color: var(--light-blue2) !important;
}

.light_blue3 {
    color: var(--light-blue3) !important;
}

.color_orange {
    color: var(--orange) !important;
}

.color_pink {
    color: var(--pink) !important;
}

.color_green {
    color: var(--green) !important;
}

.color_blue {
    color: var(--blue) !important;
}

.color_grey {
    color: var(--grey) !important;
}

.bg_blue {
    background-color: var(--dark-blue) !important;
}


.opacity_07 {
    opacity: 0.7 !important;
}

.opacity_08 {
    opacity: 0.8 !important;
}

.opacity_09 {
    opacity: 0.9 !important;
}

.cursor_pointer {
    cursor: pointer !important;
}

.fw_500 {
    font-weight: 500;
}

.glyphicon {
    transition: 0.6s ease-out;
}

.glyphicon:hover {
    -webkit-transform: rotateZ(720deg);
    -moz-transform: rotateZ(720deg);
    transform: rotateZ(720deg);
}


.bg_blue_lenear {
    background: linear-gradient(92.67deg, #01B0F9 5.11%, #505B97 114.92%);
}


.accordion-item {
    background: transparent;
    overflow: hidden;
    border: 2px solid #01B0F9 !important;
    backdrop-filter: blur(146.5px);
    border-radius: 35px !important;
}

/* something-css start  */
.icon_hov {
    transition: all 300ms ease-in;
}

.icon_hov:hover {

    transform: scale(1.2);
}

@media (max-width:767.99px) {

    .right_circle_cyber {
        top: -36% !important;
    }

    .accordion-button {
        padding: 15px 29px;
    }
}


/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    display: none !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #01B0F9;
    border-radius: 10px;
}

/* Handle */
.gain_section::-webkit-scrollbar-thumb {
    height: 0 !important;
}

/* width */
.gain_section::-webkit-scrollbar {
    height: 0 !important;
}

/* Track */
.gain_section::-webkit-scrollbar-track {
    height: 0;
}

/* Handle */
.gain_section::-webkit-scrollbar-thumb {
    height: 0;
}