.connect {
    background: lightslategray;
    padding: 80px 0;
}

.connect h3 {
    color: #fff;
    font-size: 28px;
    font-weight: 700;
}

.connect p {
    color: #fff;
}

.connect .connect-btn {
    font-family: "Raleway", sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 30px;
    border-radius: 50px;
    transition: 0.5s;
    margin: 10px;
    color: #fff;
    background: #ef6603;
}

.connect .connect-btn:hover {
    background: #fff;
    color: #ef6603;
}

@media (max-width: 1024px) {
    .connect {
        background-attachment: scroll;
    }
}

@media (min-width: 769px) {
    .connect .connect-btn-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}
